export enum GoalDirection {
  FINANCE = 1,
  HEALTH = 2,
  RELATIONSHIP = 3,
  JOY = 4,
  OTHER = 5,
}

export enum GoalStatus {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  PAUSED = 'paused',
  FUTURE = 'future',
}

/**
 * Used as goalId when selecting GoalMate chat. Indicates that GoalMate chat is selected
 */
export const GOALMATE_CHAT = 'GOALMATE_CHAT';

/**
 * Page size for archived goals
 */
export const ARCHIVED_GOALS_PER_PAGE = 8;

/**
 * Page size for goals in goal bank
 */
export const GOAL_BANK_GOALS_PER_PAGE = 15;
