import { FilterSection } from '@goalmate/presentational';
import { GoalDirection, GoalStatus } from '../constants/goal.constants';

export const GOAL_BANK_GOALS_FILTERS: FilterSection[] = [
  {
    title: 'Status',
    key: 'status',
    filters: [
      {
        label: 'Active',
        value: GoalStatus.ACTIVE,
        selected: true,
      },
      {
        label: 'Comnpleted',
        value: GoalStatus.COMPLETED,
        selected: true,
      },
      {
        label: 'Paused',
        value: GoalStatus.PAUSED,
        selected: true,
      },
      {
        label: 'Future',
        value: GoalStatus.FUTURE,
        selected: true,
      },
    ],
  },
  {
    title: 'Direction',
    key: 'direction',
    filters: [
      {
        label: 'Finance',
        value: GoalDirection.FINANCE,
        selected: true,
      },
      {
        label: 'Health',
        value: GoalDirection.HEALTH,
        selected: true,
      },
      {
        label: 'Relationship',
        value: GoalDirection.RELATIONSHIP,
        selected: true,
      },
      {
        label: 'Joy',
        value: GoalDirection.JOY,
        selected: true,
      },
      {
        label: 'Other',
        value: GoalDirection.OTHER,
        selected: true,
      },
    ],
  },
];

export const GOAL_BANK_COMPLETED_FILTERS: FilterSection[] = [
  {
    title: 'Status',
    key: 'status',
    filters: [
      {
        label: 'Comnpleted',
        value: GoalStatus.COMPLETED,
        selected: true,
      },
    ],
  },
  {
    title: 'Direction',
    key: 'direction',
    filters: [
      {
        label: 'Finance',
        value: GoalDirection.FINANCE,
        selected: true,
      },
      {
        label: 'Health',
        value: GoalDirection.HEALTH,
        selected: true,
      },
      {
        label: 'Relationship',
        value: GoalDirection.RELATIONSHIP,
        selected: true,
      },
      {
        label: 'Joy',
        value: GoalDirection.JOY,
        selected: true,
      },
      {
        label: 'Other',
        value: GoalDirection.OTHER,
        selected: true,
      },
    ],
  },
];
